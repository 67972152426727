import facebook from "../svg/facebook.svg";
import instagram from "../svg/instagram.svg";
import email from "../svg/email.svg";
// import twitter from "../svg/twitter.svg";

import styles from "./SocialMedia.module.scss";

export default function SocialMedia() {
    return (
        <div className={styles.icons}>
            <a href="https://www.facebook.com/elcaminitovoss/">
                <img src={facebook} className={styles.socialMediaIcon} alt="facebook"/>
            </a>
            <a href="https://www.instagram.com/elcaminitovoss/">
                <img src={instagram} className={styles.socialMediaIcon} alt="instagram"/>
            </a>
            <a href="mailto:info@elcaminito.no">
                <img src={email} className={styles.socialMediaIcon} alt="email" />
            </a>
            {/* <a href="#">
                <img src={twitter} className={styles.socialMediaIcon} alt="twitter"/>
            </a> */}
        </div>
    )
}