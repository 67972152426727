import { ReactNode } from "react";

import styles from "./ContentBlock.module.scss";

export interface ContentBlockProps {
    children: ReactNode;
}

export default function ContentBlock(props: ContentBlockProps) {
    const { children } = props;
    return (
        <div className={styles.contentBlock} >
            {children}
        </div>
    );
};