import { ContentBlock, H1, H2, P } from "../Catalog";
import SocialMedia from "../SocialMedia/SocialMedia";
import { aboutUs } from "./aboutUsInfo";

import styles from "./About.module.scss";

export default function About() {
    return (
        <section className={styles.about}>
            <H1 displayFont>About Us</H1>
            <ContentBlock>
                <div className={styles.aboutInfo}>
                    {aboutUs.map(info => (
                        <div className={styles.infoBlock} key={info.heading}>
                            <H2>{info.heading}</H2>
                            <P>{info.text}</P>
                        </div>
                    ))}
                    <div className={styles.infoBlock}>
                        <H2>Get in touch!</H2>
                        <SocialMedia />
                    </div>
                </div>
            </ContentBlock>
        </section>
    )
}