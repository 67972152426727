const getColors = (spiceLevel: number) => {
    switch (spiceLevel) {
        case 1: return { primary: "#6AB547", secondary: "#C4E2B6", tertiary: "#4D8433"}
        case 2: return { primary: "#EDD83D", secondary: "#FAF4C7", tertiary: "#CDB813"}
        case 3: return { primary: "#F28123", secondary: "#FBD3B2", tertiary: "#C15D0B"}
        case 4: return { primary: "#D34E24", secondary: "#EDAB96", tertiary: "#8C3318"}
        case 5: return { primary: "#C5283D", secondary: "#EA99A4", tertiary: "#871C2A"}
        case 6: return { primary: "#8F250C", secondary: "#F06242", tertiary: "#4B1306"}
        default: return { primary: "#628B48", secondary: "#B2CEA1", tertiary: "#425E31"}
    }
}

export default function Chili({spiceLevel}: {spiceLevel: number} ) {
    const colors = getColors(spiceLevel)
    
    return (
        <svg clipRule="evenodd" fillRule="evenodd" imageRendering="optimizeQuality" shapeRendering="geometricPrecision" textRendering="geometricPrecision" viewBox="0 0 512 400.27" xmlns="http://www.w3.org/2000/svg">
            <path d="m450.5 169.17c-17.29 80.32-83.45 148.85-159.98 189.89-38.58 20.7-79.82 34.45-118.78 39.29-39.41 4.87-76.65 0.66-106.69-14.75-31.91-16.38-55.53-45.12-64.91-88.62-0.33-1.58-0.08-3.25 0.79-4.75 1.88-3.16 5.96-4.25 9.13-2.37 124.69 73.49 195.97-0.5 240.3-75.7 12.79-21.75 23.49-43.78 32.62-62.53 10.33-21.33 18.66-38.49 26.49-47.37l-0.62-0.33c17.33 9.36 35.57 6.87 62.69 0.45 2.26 19.71-9.42 35.31-25.15 44.83 28.45 10 51.91 2.5 70.17-19.19 4.11 26.17 16.37 40.8 33.54 40.34l0.4 0.81z" fill={colors.tertiary} fillRule="nonzero"/>
            <path d="m6.43 316.7c-2.52-6.78-4.63-14.02-6.29-21.72-0.33-1.58-0.08-3.25 0.79-4.75 1.88-3.16 5.96-4.25 9.13-2.37 124.69 73.49 195.97-0.5 240.3-75.7 12.79-21.75 23.49-43.78 32.62-62.53 10.33-21.33 18.66-38.49 26.49-47.37 25.15 11.21 59.08 2.24 62.57-1.53 3.16 11.99-7.09 38.27-25.09 46.14 31.82 9.43 47.43 1.34 69.36-20.29l14.17 6.88c-17.29 80.32-83.45 148.86-159.98 189.89-38.58 20.7-79.82 34.45-118.77 39.29-39.42 4.87-76.66 0.66-106.7-14.75-14.87-7.63-27.93-17.94-38.6-31.19z" fill={colors.primary} fillRule="nonzero"/>
            <path d="m300.36 183.82c0.86-4.87 5.52-8.16 10.4-7.3 4.87 0.86 8.16 5.52 7.3 10.4-6.21 35.5-59.95 104.91-96.26 114.98-4.76 1.33-9.74-1.49-11.07-6.25-1.33-4.77 1.5-9.75 6.26-11.07 29.47-8.2 78.35-72.04 83.37-100.76z" fill={colors.secondary} fillRule="nonzero"/>
            <path d="m412.31 50.96c14.18-20.38 28.69-36.28 43.77-44.52 17.54-9.58 35.33-9.12 53.49 5.79 2.83 2.34 3.25 6.54 0.92 9.38-2.34 2.83-6.54 3.25-9.38 0.91-13.25-10.87-26.08-11.25-38.66-4.37-12.61 6.9-25.3 20.78-38.04 38.83 12.38 7.17 22.86 19.06 28.72 33.9 9.46 23.94 11 46.95 27.42 50.07 6.19 1.17 6.71 8.14 2.48 11.76-33.76 28.9-60.07 16.16-66.47-24.69-18.26 21.69-41.72 29.19-70.17 19.19 15.73-9.52 27.41-25.12 25.15-44.83-27.12 6.42-45.36 8.91-62.69-0.45-9.95-5.37-17.12-12.02-23.65-17.91-9.94-8.95 0.61-16.76 10.49-11.65 44.64 23.11 56.88-54.62 116.62-21.41z" fill="#479A33"/>
        </svg>
    );
}