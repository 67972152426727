export const aboutUs = [{
    heading: "Who we are",
    text: `We are Tove and Ash, a Swedish-English couple who are now calling Voss our home. We have had the shared dream of starting up a food truck for several years. After bouncing ideas and visions off each other, we've come up with a concept we both wholeheartedly believe in! Throughout many years in the restaurant industry, the two of us have gained valuable knowledge and experience that we now are able to apply in building our own business. Ash is a talented chef who has worked with amazing Mexican flavors and incorporates them into our menu. \n
    We want to see Voss as a fixed point for our business, and our mission is to build relationships with and serve local customers who can expect a great meal and good times.  This goal extends to all who visit Voss every year as well.`
}, {
    heading: "What we have to offer",
    text: `We are a food truck specializing in Mexican food with western influences, and have poured our souls into our signature dish, the burrito! We are proud to offer an awesome experience for customers of all ages. Are you a spice lover? Try out our level 6 spice burritos and see what you think! Can’t handle spice? No worries, we’ve got you sorted!`
}, {
    heading: "Conscious fast food",
    text: `At El Caminito, we try our very best to offer sustainable meals. Our pork comes from Haugen Gardsmat in Flåm, Norway who raise their free range pigs with pride. Our chicken comes from Vestfold fugl, Norway, where they focus on animal welfare and are dedicated to providing sustainable solutions at all stages of production. We also offer vegetarian / vegan alternatives that are just as attractive and tasty and will be sure to blow you away! \n
        
    This is fast food with a conscience. We can all do our part and think of the future!`
}]