import { ContentBlock, H1, H2, P } from "../Catalog";
import SpiceMeter from "./SpiceMeter";
import { MENU_ITEMS, FILLINGS, EXTRAS, KIDS_MENU, DESSERTS } from "./menuItems";

import styles from "./Menu.module.scss";

export interface MenuOption {
    header: string,
    text?: string,
    price?: number,
}

export default function Menu() {
    return (
        <section className={styles.menu}>
            <H1 displayFont>Menu</H1>
            <ol className={styles.menuList}>
                <li>
                    <span className={styles.number}>1.</span>
                    <span>What do you want to eat?</span>
                    <ul className={styles.menuItems}>
                        {MENU_ITEMS.map(item  => (
                            <li key={item.header}>
                                <ContentBlock>
                                    <div className={styles.menuItem}>
                                        <H2>{item.header}</H2>
                                        <P>{item.ingredients}</P>
                                    </div>
                                </ContentBlock>
                            </li>
                        ))}
                    </ul>
                </li>
                <li>
                    <span>Which filling?</span>
                    <span className={styles.number}>2.</span>
                    <ol className={styles.menuItems}>
                        {FILLINGS.map(item  => (
                            <li key={item.header}>
                                <ContentBlock>
                                    <div className={styles.menuItem}>
                                        <H2>{item.header}</H2>
                                        <P>{item.info}</P>
                                    </div>
                                </ContentBlock>
                            </li>
                        ))}
                    </ol>
                </li>
                <li>
                    <span className={styles.number}>3.</span>
                    <span>How spicy?</span>
                    <div className={styles.spiceMeter}>
                        <SpiceMeter />
                    </div>
                </li>
                <li>
                    <span>Any extras?</span>
                    <span className={styles.number}>4.</span>
                    <div className={styles.extras}>
                        <ContentBlock>
                            <ul className={styles.extraItems}>
                                {EXTRAS.map(item => (
                                <li className={styles.extraItem} key={item.item}>
                                    <span>{item.item}</span>
                                </li>))}
                            </ul>
                        </ContentBlock>
                    </div>
                </li>
            </ol>
            <H1 displayFont>For the kids</H1>
            <ul className={styles.menuItems}>
                {KIDS_MENU.map(item => (
                    <li key={item.item}>
                        <ContentBlock>
                            <div className={styles.menuItem}>
                                <H2>{item.item}</H2>
                                <P>{item.description}</P>
                            </div>
                        </ContentBlock>
                    </li>
                ))}
            </ul>
            <H1 displayFont>Something sweet?</H1>
            <ul className={styles.menuItems}>
                {DESSERTS.map(item => (
                    <li key={item.item}>
                        <ContentBlock>
                            <div className={styles.menuItem}>
                                <H2>{item.item}</H2>
                                <P>{item.description}</P>
                            </div>
                        </ContentBlock>
                    </li>
                ))}
            </ul>
        </section>
    )
}