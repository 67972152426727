import logo from "../svg/logo.svg";
import name from "../svg/name.svg";
import logoSlogan from "../svg/logoSlogan.svg"

export interface LogoProps {
    className: string,
    simple?: boolean,
    withSlogan?: boolean
}

export default function Logo(props: LogoProps) {
    const { className, simple, withSlogan } = props;
    let logoSvg = logo
    if (simple) logoSvg = name
    if (withSlogan) logoSvg = logoSlogan
    return (
        <img src={logoSvg} className={className} alt="logo"/>
    );
};