import { Link, Routes, Route, useLocation } from "react-router-dom";

import Nav from "./Nav/Nav";
import Home from "./Home/Home"
import Menu from "./Menu/Menu";
import About from "./About/About";
import Location from "./Location/Location";
import { Logo } from "./Catalog"

import styles from "./App.module.scss";

function App() {
  const location = useLocation()

  return (
    <div className={styles.app}>
      <header className={styles.header}>
        <Nav />
      </header>
      <main className={styles.main}>
        {location.pathname !== "/" && (
            <Link className={styles.homeButton} to={"/"}>
                <Logo className={styles.logo} withSlogan />
            </Link>
        )}
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/menu" element={<Menu />} />
            <Route path="/location" element={<Location />} />
            <Route path="/about-us" element={<About />} />
        </Routes>
      </main>
    </div>
  );
}

export default App;
