import { ContentBlock, H1, H2, P } from "../Catalog"

import styles from "./Location.module.scss"

export default function Location() {
    return (
        <section className={styles.location}>
            <H1 displayFont>Location</H1>
            <ContentBlock>
                <div className={styles.locationInfo}>
                    <H2>Where you'll find us</H2>
                    <P>We are located at: Voss Torg, 5700 Voss.</P>
                    <P>Look for the El Caminito food truck, you can’t miss us!</P>
                    <span>
                        <H2>Opening Hours:</H2>
                        <div className={styles.openingHours}>
                            <P>Please find our opening hours on </P>
                            <a className={styles.link} href="https://www.google.com/maps/place/El+Caminito/@60.6290624,6.4163198,17z/data=!3m1!4b1!4m5!3m4!1s0x463ddb02c16ffd75:0x10ae28079b652b56!8m2!3d60.6290598!4d6.4185085">
                                Google Maps
                            </a>
                        </div>
                    </span>
                    <P>Looking forward to seeing you there!</P>
                </div>
            </ContentBlock>
        </section>
    )
}
