export const MENU_ITEMS = [{
    header: "Burrito",
    ingredients: `All of our burritos come with Mexican rice, refried beans, lettuce, cheese, red cabbage, spring onions, Chipotle mayo, salsa and sour cream. Served in a 12" wholewheat tortilla`,
    image: ""
}, {
    header: "Burrito bowl",
    ingredients: "Same as the burrito, but served in a bowl instead of in a tortilla.",
    image: ""
}, {
    header: "Mexican loaded fries",
    ingredients: "All loaded fried comes with gourmet pommes, cheese, salsa, red cabbage, spring onions, Chipotle mayo and sour cream.",
    image: ""
}]

export const FILLINGS = [{
    header: "Pulled pork",
    info: "Free-range pork from Selheim Farm slow cooked with Mexican spices, orange juice, garlic and lime."
}, {
    header: "Chipotle chicken",
    info: "Chicken thighs cooked with Chipotle peppers, Adobo sauce, coriander and lime juice."
}, {
    header: "Smoky Jackfruit",
    info: "Pulled Jackfruit cooked with onion, garlic and BBQ spices."
}]

export const EXTRAS = [{
    item: "Guacamole",
    price: 25,
}, {
    item: "Jalapeños",
    price: 10,
}, {
    item: "Extra cheese",
    price: 15,
}]

export const KIDS_MENU = [{
    item: "Kids Burrito",
    description: "Mexican rice, cheese, lettuce, sour cream and your choice of filling"
}, {
    item: "Quesadilla",
    description: "Wholewheat tortilla fried with cheese and your choice of filling"
}]

export const DESSERTS = [{
    item: "Churros",
    description: "Dusted with cinnamon sugar and served with chocolate sauce"
}]