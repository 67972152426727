import { useState } from "react"
import { Chili, P } from "../Catalog"

import styles from "./SpiceMeter.module.scss"

const spiceLevels = ["Absolutely No Spice", "Easy Peasy", "Lil Spicy", "Medium Hot", "Not For Everyone", "OK This Is Flaming Hot", "Super Duper Mega Spicy"]

export default function SpiceMeter() {
    const [ spiceLevel, setSpiceLevel ] = useState(3)

    return (
        <div>
            <div className={styles.spiceMeter}>
                <div className={styles.rangeContainer}>
                    <P className={styles.spiceLevel}>{spiceLevels[spiceLevel]}</P>
                    <input className={styles.rangeSlider} type="range" min={0} max={6} value={spiceLevel} onChange={event => setSpiceLevel(parseInt(event.target.value))} />
                </div>
                <span className={styles.chilliContainer}>
                    <Chili spiceLevel={spiceLevel} />
                </span>
            </div>
        </div>
    )
}