import { NavLink } from "react-router-dom";

import styles from "./Nav.module.scss"

const getClassNames = (isActive: boolean) => {
    return `${styles.navButton} ${isActive ? styles.active : ""}`
}

const Nav = () => {
    return (
        <>
            <nav className={styles.nav}>
                <div className={styles.navButtons}>
                    <NavLink to="/" className={({isActive}) => getClassNames(isActive)}>
                        Home
                    </NavLink>
                    <NavLink to="/menu" className={({isActive}) => getClassNames(isActive)}>
                        Menu
                    </NavLink>
                    <NavLink to="/location" className={({isActive}) => getClassNames(isActive)}>
                        Location
                    </NavLink>
                    <NavLink to="/about-us" className={({isActive}) => getClassNames(isActive)}>
                        About Us
                    </NavLink>
                </div>
            </nav>
        </>
    )
}

export default Nav