import { Button, Logo, H1 } from "../Catalog"
import chili from "../svg/chili.svg"

import styles from "./Home.module.scss"

export default function Home() {
    return (
        <section className={styles.home}>
            <div className={styles.logoBlock}>
                <div className={styles.logoWrapper}>
                    <Logo className={styles.logo} withSlogan />
                </div>
                <div className={styles.hotStuff}>
                    <H1>Come visit the hottest foodtruck in Voss!</H1>
                    <img src={chili} className={styles.chili} alt="chili"/>
                </div>
                <div className={styles.buttons}>
                    <Button to="/menu">Menu</Button>
                    <Button to="/location">Find us</Button>
                </div>
            </div>
        </section>
    )
}