import { HTMLProps } from "react";

import styles from "./Typography.module.scss";

export interface HeadingProps extends HTMLProps<HTMLHeadingElement> {
    displayFont?: boolean,
}

export function H1(props: HeadingProps) {
    const { children, displayFont, ...rest } = props;
    return (
        <h1 className={`${styles.h1} ${displayFont ? styles.displayFont : ""}`} {...rest}>
            {children}
        </h1>
    )
}

export function H2(props: HeadingProps) {
    const { children, ...rest } = props;
    return (
        <h2 className={styles.h1} {...rest}>
            {children}
        </h2>
    )
}

export function P(props: HTMLProps<HTMLParagraphElement>) {
    const { children, ...rest } = props;
    return (
        <p className={styles.p} {...rest}>{children}</p>
    )
}