import { HTMLProps } from "react"
import { Link } from "react-router-dom"

import styles from "./Button.module.scss"

export interface ButtonProps extends HTMLProps<HTMLButtonElement> {
    to: string
}

export default function Button({ to, children }: ButtonProps) {
    return (
        <Link to={to} className={styles.button}>
            {children}
        </Link>
    )
}